<template>
  <iframe 
    id="previewDtouch"
    :src="url"
    style="border-radius: 5px;"
    :style="{
        width: `${size.w}px`,
        height: `${size.h}px`,
        'background-color': 'transparent',
        '-ms-zoom': scale,
        '-moz-transform': `scale(${scale})`,
        '-moz-transform-origin': '0 0',
        '-o-transform': `scale(${scale})`,
        '-o-transform-origin': '0 0',
        '-webkit-transform': `scale(${scale})`,
        '-webkit-transform-origin': '0 0',
    }"
  />
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true,
    },
    size: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    height: 0,
    scale: 0,
  }),
  mounted () {
    setTimeout(() => {
      let a = document.getElementsByClassName(this.className)[0].offsetWidth - 10
      this.height = this.size.h * a / this.size.w
      this.scale = (a * 100 / this.size.w) / 100
      /*
      this.setUserAgent(
        //document.querySelector('iframe')
        document.getElementById('previewDtouch')
        .contentWindow, 
          //'Aakash Chakravarthy Mobile Agent'
        'Mozilla/5.0 (iPhone; CPU iPhone OS 14_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Mobile/15E148 Safari/604.1'
      )*/
    }, 1000)
  },
  methods: {
    setUserAgent(window, userAgent) {
      if (window.navigator.userAgent != userAgent) {
        var userAgentProp = {
          get: function() {
            return userAgent;
          }
        };
        try {
          Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
        } catch (e) {
          window.navigator = Object.create(navigator, {
            userAgent: userAgentProp
          });
        }
      }
    },
  },
}
</script>

