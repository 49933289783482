var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-0 custom-table",attrs:{"headers":_vm.headers,"items":_vm.tableData,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.identUser",fn:function(ref){
var item = ref.item;
return [(_vm.onEditItem)?_c('a',{on:{"click":function($event){return _vm.onEditItem(item.ID)}}},[_vm._v(_vm._s(item.Name))]):_c('span',[_vm._v(_vm._s(item.Name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.Email))])]}},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [(_vm.onEditItem)?_c('a',{on:{"click":function($event){return _vm.onEditItem(item.ID)}}},[_vm._v(_vm._s(item.Name))]):_c('span',[_vm._v(_vm._s(item.Name))])]}},{key:"item.clone",fn:function(ref){
var item = ref.item;
return [(_vm.onCloneItem)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onCloneItem(item.ID)}}},[_vm._v(" mdi-content-copy ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.onCloneItem)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onCloneItem(item.ID)}}},[_vm._v(" mdi-content-copy ")]):_vm._e(),(_vm.onEditItem)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEditItem(item.ID)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.onDeleteItem)?_c('delete-item',{attrs:{"id":item.ID,"onDeleteItem":_vm.onDeleteItem,"icon":"mdi-delete","locale":_vm.locale}}):_vm._e()]}},{key:"top",fn:function(){return [_c('custom-table-title',{attrs:{"onAdd":!_vm.hideAddButton ? _vm.onEditItem : null,"title":_vm.title,"icon":_vm.icon,"onSearch":_vm.onSearch}})]},proxy:true},{key:"item.URLLink",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.URLLink}},[_vm._v(_vm._s(item.URLLink))])]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('template-users',{attrs:{"ID":item.ID}})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('template-dtouch-preview',{attrs:{"domain":item.Domain,"locale":_vm.locale}})]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(!item.image)?_c('v-icon',{staticStyle:{"padding":"20px 0"},attrs:{"color":"primary","x-large":""}},[_vm._v(" mdi-account-circle ")]):_c('v-avatar',{staticStyle:{"margin":"20px 0"},attrs:{"size":"36"}},[_c('img',{attrs:{"src":item.image,"alt":item.Name}})])]}},{key:"no-data",fn:function(){return [_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticStyle:{"margin-top":"20px"},attrs:{"border":"bottom","color":"secondary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('common.messages.noData', _vm.locale))+" "),(_vm.onEditItem)?_c('v-btn',{staticStyle:{"margin-left":"20px"},on:{"click":function($event){return _vm.onEditItem(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.newItem', _vm.locale))+" ")]):_vm._e()],1)]},proxy:true}],null,true)}),_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"count":_vm.count,"onGetData":_vm.onGetData,"locale":_vm.locale}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }