<template>
  <v-toolbar
    flat
    style="padding-top: 5px;"
  >
  <v-toolbar-title v-if="title || icon"><v-icon v-if="icon" x-large>{{ icon }}</v-icon> {{ title }}</v-toolbar-title>
    <!--v-divider
      v-if="title"
      class="mx-4"
      inset
      vertical
    /-->
    <v-btn
      v-if="onGenerateMediaFeed"
      @click="onGenerateMediaFeed"
      color="primary"
      style="margin-left: 10px"
    >
      <v-icon>mdi-file-refresh-outline</v-icon>
    </v-btn>
    <v-spacer />
    <search v-if="onSearch" :onSearch="onSearch" />
    <v-btn
      v-if="onAdd"
      color="primary"
      dark
      class="mb-2"
      @click="onAdd(null)"
      fab
      elevation="0"
      small
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-toolbar>
</template>
<script>
  import Search from '@/components/Search'
  export default{
    components: {
      Search
    },
    props: {
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      onAdd: {
        type: Function,
        default: null
      },
      onGenerateMediaFeed: {
        type: Function,
        default: null,
      },
      onSearch: {
        type: Function,
        default: null
      },
    },
  }
</script>

